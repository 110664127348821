import Head from "next/head";
import PageLayout, { siteTitle } from "@/components/PageLayout";
import Link from "next/link";
import Image from "next/image";
import ProductService from "@/lib/services/products.js";
import DropdownFilter from "@/components/dropdown_filter";
import AnalyticService from "@/lib/services/analytics.js";
import ProductCompareBar from "@/components/product_compare_bar";
import Paginator from "@/components/paginator";
import InfiniteScroll from "@/components/infinite_scroll.js";
import ProductFilterSidebar from '@/components/product_filter_sidebar';
import ProductTile from "@/components/product_tile";
import SearchBuilder from "@/components/search_builder.js";
import { useState, useReducer, useEffect, useContext } from "react";
import Qs from "qs";
import searchTranslator from "@/lib/search_translator.js";
import Loader from "@/components/loading_animation.js";
import { loadConfig } from "@/lib/utils.js";
import { productRatingOptions, sortOptions } from "@/lib/static_data.js";
import {ProductCacheContext } from '@/lib/product_cache_context.js'; 

import compareProductReducer, {
  loadFromStorage,
} from "@/lib/compare_product_reducer.js";
import loadProductTypes from "@/lib/load_product_types.js";


function ProductSearchPage(results) {
  const {
    products,
    rating_aggregation,
    collection_filters, 
    attribute_filters,
    total_count,
    initialSearch,
    page_layout,
    favicon,
    stylesheet,
    cart_enabled,
    attribute_filters_position, 
    enable_infinite_scroll_on_product_pages


  } = results;


  const useInfiniteScroll = enable_infinite_scroll_on_product_pages; 
  const typeOptions = loadProductTypes(results);
  const [compareProducts, dispatch] = useReducer(compareProductReducer, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageChange, setIsPageChange] = useState(false)

  const prodCache = useContext(ProductCacheContext); 

  
  const selectedProductType = (search) => {
    if (!search) {
      return;
    }
    if (!search["q[tags_id_eq]"]) {
      return "";
    }
    return typeOptions.find(
      (type, i) => type.value.toString() === search["q[tags_id_eq]"].toString()
    );
  };

  const searchProducts = (search, pageChange) => {
    setIsLoading((currentIsLoaded) => true);
    setIsPageChange(pageChange)
    if (!pageChange) {
     useInfiniteScroll ?  prodCache.setPage(1) : null;  
    }
    fetch(
      "/lex-api/products" +
        "?" +
        Qs.stringify(search, { arrayFormat: "brackets", encode: true })
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading((currentIsLoaded) => false);
        (useInfiniteScroll && pageChange)  ? prodCache.appendProducts(data.products ) : prodCache.setItems(data.products)  
        prodCache.setTotalCount(data.total_count);
        prodCache.setAttributeFilters(data.attribute_filters);
        prodCache.setCollectionFilters(data.collection_filters);
        prodCache.setRatingAggregation(data.rating_aggregation);
        if (!pageChange) {
          dispatch({
            type: "filterByCurrentProducts",
            products: data.products,
          });
        }
      });
  };

  useEffect(() => {
    prodCache?.items?.length > 0 ? null : prodCache.setItems(products); 
    prodCache?.totalCount >  0 ? null: prodCache.setTotalCount(total_count);
    prodCache?.attributeFilters.length > 0 ?  null : prodCache.setAttributeFilters(attribute_filters || [])
    prodCache?.collectionFilters?.length > 0 ? null: prodCache.setCollectionFilters(collection_filters || [])
    Object.keys(prodCache?.ratingAggregation || {}).length > 0 ? null: prodCache.setRatingAggregation(rating_aggregation || {})

  }, [])

  useEffect(() => {
    let ids = loadFromStorage();
     prodCache.items?.filter((prod) => {
        return ids.includes(prod.id.toString());
      })
      .forEach((prod, i) => {
        dispatch({ type: "addCompareProduct", product: prod });
      });
   }, []);
  return (
    <PageLayout
      title="Product Search"
      page_layout={page_layout}
      cart_enabled={cart_enabled}
      favicon={favicon}
      stylesheet={stylesheet}
    >
      <SearchBuilder
        initialSearch={initialSearch}
        search={searchProducts}
      >
        {(searchState) => {
          return (
            <section className="lexcms-product-search-page-container">
              <section className="lexcms-product-search-page-header-container">
                <div className="lexcms-page-body-header-text">
                  {selectedProductType(searchState.search) && (
                    <h1 className="lexcms-product-search-selected-product-type">
                      {selectedProductType(searchState.search).label}
                    </h1>
                  )}
                  {!selectedProductType(searchState.search) && (
                    <h1 className="lexcms-product-search-selected-product-type">
                      All Products
                    </h1>
                  )}

                  <p className="lexcms-product-search-refine-products-count">
                    Refine {prodCache.totalCount} Products
                  </p>
                </div>
                <div className="lexcms-product-search-top-filter-container">
                  <div className="lexcms-product-search-top-filters">
                    <DropdownFilter
                      searchKey={"q[tags_id_eq]"}
                      className="lexcms-product-search-top-product-type-filter"
                      searchState={searchState}
                      options={typeOptions}
                      label={"Product Type Filter"}
                    />
                    {prodCache.showRatingFilter() && (
                      <DropdownFilter
                        searchKey={"q[product_rating_eq]"}
                        className={"lexcms-product-search-top-rating-filter"}
                        searchState={searchState}
                        options={prodCache._productRatingOptions()}
                        label={"Rating Filter"}
                      />
                    )}
                    {prodCache.collectionFilters?.length > 0 &&
                    <DropdownFilter
                      searchKey={"q[collections_id_eq]"}
                      className={"lexcms-product-search-top-collections-filter"}
                      searchState={searchState}
                      options={prodCache.collectionFilters}
                      label={"Collections Filter"}
                    />
                    }
                    <button
                      onClick={() => {
                        searchProducts({});
                        searchState.clearAll();
                        dispatch({ type: "clearAllComparisons" });
                      }}
                      className="lexcms-regular-button lexcms-product-search-clear-results-button"
                    >
                      Clear Results
                    </button>
                  </div>
                  <div className="lexcms-product-search-top-filter-pills-container">
                    {prodCache.selectedAttributes(searchState.search).map((attr, i) => {
                      return (
                        <div
                          key={i}
                          className="lexcms-product-search-top-filter-pill"
                        >
                          {attr.display_value}
                          <div
                            onClick={() =>
                              searchState.removeFromValuesAtKey(
                                "q[has_attribute_values][]",
                                attr.id.toString()
                              )
                            }
                            className="lexcms-product-search-top-filter-pill-close"
                          >
                            x
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
              {!useInfiniteScroll &&
              <div className="lex-cms-pagination-top">
                {prodCache.totalCount > 50 && (
                  <Paginator
                    itemsPerPage={50}
                    pageRangeDisplayed={5}
                    totalCount={prodCache.totalCount}
                    search={searchState}
                  />
                )}
              </div>
              }
              <section className="lexcms-product-search-page-body-container">
                <ProductFilterSidebar attributeFiltersPosition={attribute_filters_position} attributeFilters={prodCache.attributeFilters}
                searchState={searchState}/>
                <div
                  className={
                    "lexcms-product-tile-grid-container " +
                    (prodCache.attributeFilters.length > 0
                      ? "lexcms-product-search-tile-grid-container-with-sidebar"
                      : "lexcms-product-search-tile-grid-container-no-sidebar")
                  }
                >
                  {isLoading && (!useInfiniteScroll || !isPageChange) && <Loader />}
                  {prodCache.items?.length <= 0 && (
                    <div className="lexcms-no-results-found-container">
                      <div className="lexcms-no-results-found-image"></div>
                      <h2 className="lexcms-no-results-found-text">
                        No Results Found. Try Refining Your Search
                      </h2>
                    </div>
                  )}
                  {prodCache.items &&
                    prodCache.items.length > 0 &&
                    prodCache.items.map((product, i) => {
                      return (
                        <ProductTile
                        attributesApplied={prodCache.attributesApplied}
                          addCompareProduct={() =>
                            dispatch({
                              type: "addCompareProduct",
                              product: product,
                            })
                          }
                          prodRemove={() =>
                            dispatch({ type: "prodRemove", product: product })
                          }
                          canCompare={
                            selectedProductType(searchState.search)
                              ? true
                              : false
                          }
                          isCompared={compareProducts
                            .map((prod, i) => prod.id)
                            .includes(product.id)}
                          key={product.id}
                          product={product}
                          compareProducts={compareProducts}
                        />
                      );
                    })}
                </div>
              </section>
               {useInfiniteScroll ? (<InfiniteScroll itemsPerPage={50}
                 pageRangeDisplayed={5}
                 totalCount={prodCache.totalCount}
                 loading={isLoading}
                 search={searchState} /> ) :
               (<div className="lex-cms-pagination-bottom">
                {prodCache.totalCount > 50 && (
                  <Paginator
                    itemsPerPage={50}
                    pageRangeDisplayed={5}
                    totalCount={prodCache.totalCount}
                    search={searchState}
                  />
                )}
              </div> ) 
              }
              {compareProducts.length > 0 &&
                selectedProductType(searchState.search) && (
                  <div className="lexcms-product-comparisons-container">
                    <ProductCompareBar
                      products={compareProducts}
                      prodRemove={(product) =>
                        dispatch({ type: "prodRemove", product: product })
                      }
                      typeId={selectedProductType(searchState.search).value}
                      clearAll={() => dispatch({ type: "clearAllComparisons" })}
                    />
                  </div>
                )}
            </section>
          );
        }}
      </SearchBuilder>
    </PageLayout>
  );
}

export async function getServerSideProps(context) {
  let  token = context.req?.headers['lex-api-token'];
  let service = await ProductService.build(
    token,
    context.req?.headers.host
  );
  let search = {}
  let pageConfig = loadConfig(service);
  let q = searchTranslator(context.query);
  search.initialSearch = q;
  search.productTypes = service.config.product_types
    ? service.config.product_types
    : null;

  if (context.query?.clientNavigation) {
    //back navigation so data should already be in memory

    return {props: { ...pageConfig,...search} }
  }

  let apiFriendlyQ = searchTranslator(context.query, true);
  let results  = await service.index(apiFriendlyQ);
  search = {...search, ...results}
  try {
    if(context.query?.tags_id_eq) {
      let analyticService = new AnalyticService(token, {}, true);
      let data = {
        tag_id: context.query.tags_id_eq
      }
      analyticService.recordProductTypePageVisit(data, context.req?.headers || {});
    }
  } catch (e) {
    console.log(e.message);
  }
  try {
    if (context.query.document_eq) {
     let analyticService = new AnalyticService(token, {}, true);
      analyticService.recordProductSearch(
        context.query.document_eq,
        context.req?.headers || {}
      );
    }
  } catch (e) {
    console.log(e);
  }
  
  return {
    props: { ...search, ...pageConfig },
  };
}

export default ProductSearchPage;
